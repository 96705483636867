@font-face {
    font-family: 'Neue Montreal';
    src: url('./fonts/NeueMontreal-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

* {
    font-family: 'Neue Montreal', sans-serif;
}

.z--1{
    z-index: -1;
}

.z--2{
    z-index: -2;
}

.bg-custom-gradient {
    background-image: linear-gradient(to bottom, #1A1A1A 0%, #1A1A1A 75%, #3A414D 75%, #3A414D 100%);
}

.bg-custom-gradient1 {
    background-image: linear-gradient(to bottom right, #1A1A1A 0%, #1A1A1A 60%, #3A414D 60%, #3A414D 100%);
}


.bg-primary-transparent {
    background-color: rgba(58, 65, 77, 0.3);
}

.bg-primary {
    background-color: #3A414D;
}

.bg-secondary {
    background-color: #1A1A1A;
}

.bg-light {
    background-color: #F5F4F0;
}

.text-primary {
    color: #3A414D;
}

.text-secondary {
    color: #1A1A1A;
}

.text-orange {
    color: #FF8E47;
}

.text-light {
    color: #F5F4F0;
}

.fill-primary {
    fill: #1A1A1A;
    transition: fill 0.3s ease;
}

.fill-primary:hover {
    fill: #F5F4F0;
}

.fill-secondary {
    fill: #FF8E47;
    transition: fill 0.3s ease;
}

.fill-secondary:hover {
    fill: #F5F4F0; 
}

.fill-light {
    fill: #F5F4F0;
    transition: fill 0.3s ease;
}

.fill-light:hover {
    fill: #3A414D;
}

.hidden-until-visible {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .animate__visible {
    opacity: 1;
  }

  .slides .slick-prev,
.slides .slick-next {
  position: absolute;
  top: 50%;
  z-index: 1;
}